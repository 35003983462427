import {Navigate, useRoutes} from 'react-router-dom';
import Login from "./components/login/login";
import TemplateLayout from "./components/TemplateLayout";
import Subscribe from "./components/login/subscribe";
import Claim from "./components/claim/claim";
import {isAuthenticated} from "./utils/axiosApi";
import Success from "./components/claim/success";

export default function Router() {
    const templateElement =  isAuthenticated() ? <TemplateLayout /> : <Navigate to='/login' />;

    return useRoutes([
        {
            path: '/login',
            element: isAuthenticated() ? <Navigate to='/claim' /> : <TemplateLayout login={true}/>,
            children: [
                { path: '/login', element: <Login /> },
                { path: '/login/subscribe', element: <Subscribe /> }
            ]
        },
        {
            path: '/claim',
            element: templateElement,
            children: [
                { path: '/claim', element: <Claim /> },
                { path: '/claim/success', element: <Success /> },
            ]
        },
        {
            path: '*',
            element: <Navigate to="/login" replace />
        }
    ]);
}