import React, {useCallback, useEffect} from "react";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ListItemText from "@mui/material/ListItemText";
import {useDropzone} from "react-dropzone";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Link,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    checkChipNumber,
    checkInsuredAnimal,
    createClaim,
    getQuoteClaim,
} from "../../utils/axiosRequests";
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ClearIcon from '@mui/icons-material/Clear';
import _ from "lodash";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {DatePicker} from "@mui/lab";
import MyModal from "../molecules/Modal"
import Exclusions from "./exclusions";
import InputAdornment from '@mui/material/InputAdornment';
import CircularIndeterminate from "../molecules/Loading";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const animal_inputs = [
    {
        label: 'N° de puce de l\'animal',
        name: 'chipNumber',
        type: 'text'
    },
    {
        label: 'Email de l\'assuré',
        name: 'userEmail',
        type: 'email'
    },
    {
        label: 'Nom de l\'animal',
        name: 'animalName',
        type: 'text'
    },
]

const useStyles = makeStyles(() => ({
    input: {
        '& input': {
            padding: 10
        },
        '& fieldset': {
            borderWidth: 2
        }
    },
    radio: {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
    },
    card: {
        marginTop: '20px',
        backgroundColor: 'aliceblue',
        borderRadius: '20px',
        paddingLeft: '20px',
        width: '1100px',
        paddingTop: '30px',
        paddingBottom: '30px',
        "@media (max-width: 1160px)": {
            width: '960px',
        }
    },
    claimTypes: {
        display: 'flex',
        marginLeft: '40px',
        marginRight: '60px',
        "@media (max-width: 1160px)": {
            marginLeft: '10px',
            marginRight: '30px',
        }
    }
}));

const CLAIM_TYPES = [
    {
        value: 'illness',
        label: 'Maladie'
    },
    {
        value: 'accident',
        label: 'Accident'
    },
    {
        value: 'prevention',
        label: 'Prévention y compris vaccination ou stérilisation'
    },
]


const redFlag = <DoNotDisturbOnRoundedIcon color="error"/>;
const greenFlag = <CheckCircleRoundedIcon color="success"/>;
const loading = <CircularProgress style={{ height: '25px', width: '25px' }}/>

const warning_values = (contractItem, contract) => [
    {
        label: "Carence maladie",
        icon: contractItem?.isIllnessLocked ? redFlag : greenFlag
    },
    {
        label: "Carence accident",
        icon: contractItem?.isAccidentLocked ? redFlag : greenFlag
    },
    {
        label: "Carence chirurgie",
        icon: contractItem?.isSurgeryLocked ? redFlag : greenFlag
    },
    {
        label: "Limite annuelle",
        icon: contract?.alertYearLimit ? redFlag : greenFlag
    },
]

const minDate = new Date("1900-01-01")
const maxDate = new Date()
maxDate.setUTCHours(23,59,59,999);

const schema = yup.object().shape({
    typeCare: yup.string().required(),
    isSurgery: yup.boolean().required(),
    cost: yup.number().min(0).required(),
    date: yup.date().min(minDate).max(maxDate).required(),
    invoice: yup.string().optional().nullable(),
    context: yup.string().optional().nullable(),
});

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export default function Claim() {
    const classes = useStyles()
    const [showAnimalSearch, setShowAnimalSearch] = React.useState(false);
    const [animalInfoValues, setAnimalInfoValues] = React.useState(null);
    const [chipNumber, setChipNumber] = React.useState(null);
    const [quoteClaimRef, setQuoteClaimRef] = React.useState(null);
    const [chipNumberIcon, setChipNumberIcon] = React.useState(null);
    const [claimQuoteRefIcon, setclaimQuoteRefIcon] = React.useState(null);
    const [insuredEmailIcon, setInsuredEmailIcon] = React.useState(null);
    const [animalNameIcon, setAnimalNameIcon] = React.useState(null);
    const [contract, setContract] = React.useState(false);
    const [contractItem, setContractItem] = React.useState(false);
    const [claimFiles, setClaimFiles] = React.useState([]);
    const [openExclusionsModal, setOpenExclusionsModal] = React.useState(false);
    const [warningValues, setWarningValues] = React.useState([]);
    const [blockingMessage, setBlockingMessage] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const {control, formState, handleSubmit, watch, reset} = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const {isValid, isDirty} = formState

    const typeCareValue = watch('typeCare')
    const isSurgeryValue = watch('isSurgery')
    const costValue = watch('cost') || 0

    const navigate = useNavigate();
    const { numberOrder } = useSelector((state) => state.account)
    const redirectToClaimSuccess = () => {
        navigate('/claim/success');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChipNumberChangeHandler = useCallback(
        _.debounce(chipNumber => {
            onChipNumberChange(chipNumber)
        }, 800),
        []);

    useEffect(() => {
        if (!chipNumber?.trim()) return;
        setChipNumberIcon(loading)
        debouncedChipNumberChangeHandler(chipNumber)
    }, [chipNumber, debouncedChipNumberChangeHandler]);

    const onChipNumberChange = (value) => {
        checkChipNumber(value)
            .then(({ data }) => {
                setChipNumberIcon(data?.isValid ? greenFlag : redFlag)
                setContract(data?.isValid ? data.contract : null)
                console.log('toto')
                setContractItem(data?.isValid ? data.contractItem : null)
                setclaimQuoteRefIcon(null)
            })
            .catch(() => {
                setChipNumberIcon(redFlag)
            })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedNotesChangeHandler = useCallback(
        _.debounce(animalInfoValues => {
            onAnimalInfoValuesChange(animalInfoValues)
        }, 1000),
        []);

    useEffect(() => {
        if (!animalInfoValues) return;
        if (_.isEmpty(animalInfoValues.userEmail?.trim())|| !emailPattern.test(animalInfoValues.userEmail?.trim())) return
        setInsuredEmailIcon(loading)
        if (!_.isEmpty(animalInfoValues.animalName?.trim())) setAnimalNameIcon(loading)
        debouncedNotesChangeHandler(animalInfoValues)
    }, [animalInfoValues, debouncedNotesChangeHandler]);

    const onAnimalInfoValuesChange = (animalInfoValues) => {
        checkInsuredAnimal(animalInfoValues)
            .then(({ data }) => {
                setInsuredEmailIcon(data?.isValidUser ? greenFlag : redFlag)
                if (!_.isEmpty(animalInfoValues.animalName)) setAnimalNameIcon(data?.isValidAnimal ? greenFlag : redFlag)
                setContract((data?.isValidAnimal && data?.isValidUser) ? data.contract : null)
                setContractItem((data?.isValidAnimal && data?.isValidUser) ? data.contractItem : null)
                setclaimQuoteRefIcon(null)
            })
            .catch(() => {
                setInsuredEmailIcon(redFlag)
                if (!_.isEmpty(animalInfoValues.animalName)) setAnimalNameIcon(redFlag)
            })
    }

    useEffect(() => {
        if (contractItem && contract) setWarningValues(warning_values(contractItem, contract))
    }, [contractItem, contract])

    const onSubmit = (data) => {
        setIsLoading(true)
        setErrorMessage(null)
        data = { contractId: contract.id, contractItemId: contractItem.id, ...data }
        const formData = new FormData();
        if (claimFiles?.length) claimFiles.forEach((file) => formData.append('files', file));
        Object.keys(data).forEach((key) =>
            formData.append(key, key === 'date' ? new Date(data[key]).toLocaleDateString() : data[key])
        );
        formData.append('numberOrder', numberOrder)
        createClaim(formData)
            .then(() => {
                redirectToClaimSuccess()
            })
            .catch(error => {
                setErrorMessage(error.response?.data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedQuoteClaimRefChangeHandler = useCallback(
        _.debounce(quoteClaimRef => {
            onQuoteClaimRefChange(quoteClaimRef, contractItem)
        }, 1000),
        []);

    useEffect(() => {
        if (!quoteClaimRef?.trim()) return;
        setclaimQuoteRefIcon(loading)
        debouncedQuoteClaimRefChangeHandler(quoteClaimRef)
    }, [quoteClaimRef, debouncedQuoteClaimRefChangeHandler]);

    const onQuoteClaimRefChange = (value, contractItem) => {
        getQuoteClaim({ ref: value?.trim(), contractItemId: contractItem.id })
            .then(({ data }) => {
                setclaimQuoteRefIcon(data?.isValid ? greenFlag : redFlag)
                if (data?.isValid) {
                    const values = { ..._.pick(data.claim, ['context', 'typeCare', 'cost']), isSurgery: data.claim.surgery }
                    reset(values)
                }
            })
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        // accept: "image/*, application/pdf",
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setClaimFiles([...claimFiles, ...acceptedFiles])
        }
    });

    const deleteFile = (name) => {
        setClaimFiles(claimFiles.filter(f => f.name !== name))
    }

    const ButtonDocument = ({name}) =>
        <div style={{display: 'inline'}}>
            <Button variant={"outlined"}
                    style={{margin: '5px', cursor: 'unset'}}>
                {name}
                <IconButton onClick={(event) => deleteFile(name)}
                            style={{ padding: 0, marginLeft: '12px'}}>
                    <ClearIcon color="primary"/>
                </IconButton>
            </Button>
        </div>

    useEffect(() => {
        const deficiencyText = 'Malheureusement, les soins ne sont pas remboursable car ils sont survenus pendant la période de carence'
        setBlockingMessage(null)
        if (isSurgeryValue && contractItem?.isSurgeryLocked) setBlockingMessage(`${deficiencyText} chirurgie`)
        if (typeCareValue === 'illness' && contractItem?.isIllnessLocked) setBlockingMessage(`${deficiencyText} maladie`)
        if (typeCareValue === 'accident' && contractItem?.isAccidentLocked) setBlockingMessage(`${deficiencyText} accident`)
        if (typeCareValue === 'prevention') setBlockingMessage('Ce type de prestation n\'est pas remboursable')
    }, [contractItem, typeCareValue, isSurgeryValue])

    return (
        <>
            <Typography variant={"h2"} style={{display: 'flex', justifyContent: 'center', fontSize: '40px'}}>
                Demander un paiement par Tiers-Payant
            </Typography>

            <Typography fontSize={18} style={{marginTop: '50px', display: 'flex', justifyContent: 'center'}}>
                Drooky.com règle directement les vétérinaires
            </Typography>
            <Typography fontSize={18} style={{display: 'flex', justifyContent: 'center'}}>
                Nous effectuons des virements instantanés deux fois par jour
            </Typography>
            <section style={{marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                <section className={classes.card}>
                    <Typography variant={'h5'}>
                        Animal concerné par les soins
                    </Typography>
                    <ListItem style={{height: '50px', marginTop: '30px', maxWidth: "676px", minWidth: "500px"}}
                              key={animal_inputs[0].name}
                              disablePadding>
                        <ListItemIcon>
                            <InfoOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={animal_inputs[0].label + ':'}/>
                        <TextField style={{width: 200}}
                                   defaultValue={undefined}
                                   type={animal_inputs[0].type}
                                   onChange={(e) => {
                                       setChipNumber(e.target.value)
                                   }}
                                   InputProps={{className: classes.input}}/>
                        <ListItemIcon style={{ marginLeft: '20px'}}>{chipNumberIcon}</ListItemIcon>
                    </ListItem>
                    <Typography style={{ marginTop: '15px', fontSize: 18, marginBottom: showAnimalSearch ? '10px' : 0 }}>
                        <Link style={{ cursor:'pointer' }}
                              onClick={() => {
                                  setShowAnimalSearch(!showAnimalSearch)
                              }}>Je n'ai pas le numéro de puce de l'animal ?</Link>
                    </Typography>
                    {showAnimalSearch ?
                        <>
                            <ListItem style={{height: '50px', width: "676px", minWidth: "500px"}}
                                      key={animal_inputs[1].name}
                                      disablePadding>
                                <ListItemIcon>
                                    <InfoOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary={animal_inputs[1].label + ':'}/>
                                <TextField style={{width: 200}}
                                           defaultValue={undefined}
                                           type={animal_inputs[1].type}
                                           onChange={(e) => {
                                               setAnimalInfoValues({ ...animalInfoValues, userEmail: e.target.value?.trim().toLowerCase() })
                                           }}
                                           InputProps={{className: classes.input}}/>
                                <ListItemIcon style={{ marginLeft: '20px'}}>{insuredEmailIcon}</ListItemIcon>
                            </ListItem>
                            <ListItem style={{height: '50px', maxWidth: "676px", minWidth: "500px"}}
                                      key={animal_inputs[2].name}
                                      disablePadding>
                                <ListItemIcon>
                                    <InfoOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary={animal_inputs[2].label + ':'}/>
                                <TextField style={{width: 200}}
                                           defaultValue={undefined}
                                           type={animal_inputs[2].type}
                                           onChange={(e) => {
                                               setAnimalInfoValues({ ...animalInfoValues, animalName: e.target.value?.trim()?.toLowerCase() })
                                           }}
                                           InputProps={{className: classes.input}}/>
                                <ListItemIcon style={{ marginLeft: '20px'}}>{animalNameIcon}</ListItemIcon>
                            </ListItem>
                        </>:
                    null}
                </section>
                {!contract ?
                    <Typography variant={'h6'} style={{marginTop: '50px'}}>
                        Si vous ne trouvez pas l’animal, appelez-nous au numéro d’urgence suivant: 07 72 10 12 05
                    </Typography> :
                    <>
                        <section className={classes.card}>
                            <Typography variant={'h5'}>
                                Contrôles
                            </Typography>
                            <div style={{ marginTop: '30px'}}>
                                {warningValues.map(v=>
                                    <ListItem style={{maxWidth: "600px", minWidth:"550px"}}
                                              disablePadding
                                              key={v.label}>
                                        <ListItemIcon>
                                            {v.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={v.label} />
                                        <Typography style={{width:"200px", marginLeft:'5px', textAlign:'left'}}>{v.labelValue}</Typography>
                                    </ListItem>
                                )}
                            </div>
                        </section>
                        {contract?.alertYearLimit ?
                            <Typography variant={'h6'} style={{marginTop: '50px'}}>
                                Cet utilisateur a consommé l’intégralité de sa couverture d’assurance. Nous ne pouvons malheureusement plus rembourser ses frais de soins
                            </Typography> :
                            <form onSubmit={handleSubmit(onSubmit)}
                             style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <section className={classes.card}>
                                    <Typography variant={'h5'}>Faits générateur</Typography>
                                    <ListItem style={{ marginTop: '30px', height: '50px', width: "946px", minWidth: "500px"}}
                                              key={'quoteId'}
                                              disablePadding>
                                        <ListItemIcon>
                                            <InfoOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={'N° de devis Drooky (optionnel):'}/>
                                        <TextField style={{width: 200}}
                                                   onChange={(e) => {
                                                       setQuoteClaimRef(e.target.value)
                                                   }}
                                                   defaultValue={undefined}
                                                   type={'text'}
                                                   InputProps={{className: classes.input}}/>
                                        <ListItemIcon style={{ marginLeft: '20px'}}>{claimQuoteRefIcon}</ListItemIcon>
                                        <Typography style={{width: '270px'}}>Les champs seront automatiquement remplis par les info du devis</Typography>
                                    </ListItem>
                                    <div style={{display: 'flex', marginTop: '30px'}}>
                                        <div className={classes.claimTypes}>
                                            <Controller control={control}
                                                        name={'typeCare'}
                                                        defaultValue={CLAIM_TYPES[0].value}
                                                        render={({field, fieldState}) =>
                                                            <RadioGroup {...field}
                                                                        style={{
                                                                            marginTop: 10,
                                                                            marginBottom: 10,
                                                                            display: 'flex',
                                                                            flexDirection: 'row'
                                                                        }}>
                                                                {CLAIM_TYPES.map(claimType =>
                                                                    <FormControlLabel key={claimType.value}
                                                                                      value={claimType.value}
                                                                                      style={{
                                                                                          zIndex: 100,
                                                                                          height: '30px',
                                                                                          paddingRight: '20px',
                                                                                          paddingLeft: '20px',
                                                                                          marginRight: '10px',
                                                                                          justifyContent: 'center',
                                                                                          border: '1px solid black',
                                                                                          backgroundColor: field.value === claimType.value ? 'lightskyblue' : undefined
                                                                                      }}
                                                                                      control={<Radio
                                                                                          className={classes.radio}/>}
                                                                                      label={
                                                                                          <span>{claimType.label}</span>}/>
                                                                )}
                                                            </RadioGroup>}/>
                                        </div>
                                        <ListItem style={{height: '50px', width: "300px", display: (blockingMessage && typeCareValue === 'prevention') ? 'none' : 'flex'}}
                                                      disablePadding>
                                                <ListItemText primary={'Un acte chirurgical a été pratiqué :'}/>
                                                <Controller control={control}
                                                            name={'isSurgery'}
                                                            defaultValue={false}
                                                            render={({field, fieldState}) =>
                                                                <Checkbox error={!!fieldState.error}
                                                                          checked={field.value}
                                                                          {...field}/>
                                                            }/>
                                            </ListItem>
                                    </div>
                                    {blockingMessage ?
                                        <Typography variant={'h6'} style={{marginTop: '50px'}}>
                                            {blockingMessage}
                                        </Typography> :
                                        <ListItem disablePadding
                                                  style={{
                                                      height: '50px',
                                                      marginTop: '30px',
                                                      maxWidth: "600px",
                                                      minWidth: "500px"
                                                  }}>
                                            <ListItemIcon>
                                                <InfoOutlinedIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={'Date de survenance (de l\'accident ou du début de la maladie):'}
                                                          style={{marginRight: '80px', width: '200px'}}/>
                                            <div style={{width: 200, display: 'flex', alignItems: 'center'}}>
                                                <Controller control={control}
                                                            name={'date'}
                                                            defaultValue={null}
                                                            render={({field, fieldState}) =>
                                                                <DatePicker renderInput={(props) => <TextField {...props}/>}
                                                                            InputProps={{className: classes.input}}
                                                                            minDate={minDate}
                                                                            maxDate={new Date()}
                                                                            error={!!fieldState.error}
                                                                            {...field}/>
                                                            }/>
                                            </div>
                                        </ListItem>}
                                </section>
                                {blockingMessage ?
                                    null :
                                    <>
                                        <section className={classes.card}>
                                            <Typography variant={'h5'}>Montant des frais de soins</Typography>
                                            <ListItem style={{
                                                height: '50px',
                                                maxWidth: "600px",
                                                minWidth: "500px",
                                                marginTop: '30px'
                                            }}
                                                      disablePadding>
                                                <ListItemIcon>
                                                    <InfoOutlinedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Coût total TTC:'}/>
                                                <div style={{width: 200, display: 'flex', alignItems: 'center'}}>
                                                    <Controller control={control}
                                                                name={'cost'}
                                                                defaultValue={undefined}
                                                                render={({field, fieldState}) =>
                                                                    <TextField type={'number'}
                                                                               InputProps={{
                                                                                   className: classes.input,
                                                                                   endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                                               }}
                                                                               error={!!fieldState.error}
                                                                               {...field}/>
                                                                }/>
                                                </div>
                                            </ListItem>
                                            <ListItem style={{height: '50px', maxWidth: "600px", minWidth: "500px"}}
                                                      disablePadding>
                                                <ListItemIcon>
                                                    <InfoOutlinedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Part Drooky:'}/>
                                                <Typography style={{width: 200}}>
                                                    {(costValue * contract.refund).toFixed(2).replace('.', ',')} € (Taux
                                                    de {contract.refund * 100} %)
                                                </Typography>
                                            </ListItem>
                                            <ListItem style={{height: '50px', maxWidth: "600px", minWidth: "500px"}}
                                                      disablePadding>
                                                <ListItemIcon>
                                                    <InfoOutlinedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Reste à charge:'}/>
                                                <Typography style={{width: 200}}>
                                                    {(costValue - (costValue * contract.refund)).toFixed(2).replace('.', ',')} €
                                                </Typography>
                                            </ListItem>
                                            <ListItem style={{height: '50px', maxWidth: "900px", minWidth: "500px"}}
                                                      disablePadding>
                                                <ListItemIcon>
                                                    <InfoOutlinedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Référence de la facture (optionnel):'}/>
                                                <Controller control={control}
                                                            name={'invoice'}
                                                            defaultValue={undefined}
                                                            render={({field, fieldState}) =>
                                                                <TextField style={{width: 200}}
                                                                           type={'text'}
                                                                           InputProps={{className: classes.input}}
                                                                           error={!!fieldState.error}
                                                                           {...field}/>
                                                            }/>
                                                <Typography style={{width: '270px', marginLeft: '30px'}}>Cette référence sera dans le libellé de notre virement bancaire</Typography>
                                            </ListItem>
                                            <ListItem style={{height: '140px', maxWidth: "750px", minWidth: "500px"}}
                                                      disablePadding>
                                                <ListItemIcon>
                                                    <InfoOutlinedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Contexte (optionnel):'}/>
                                                <Controller control={control}
                                                            name={'context'}
                                                            defaultValue={undefined}
                                                            render={({field, fieldState}) =>
                                                                <TextField style={{width: 350}}
                                                                           multiline
                                                                           minRows={3}
                                                                           InputProps={{className: classes.input}}
                                                                           error={!!fieldState.error}
                                                                           {...field}/>
                                                            }/>
                                            </ListItem>
                                            <div style={{marginTop: '30px', width: '700px'}}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <Typography>Joindre la facture ou autres fichiers liés au
                                                        sinistre</Typography>
                                                    <IconButton style={{marginRight: '20px'}}
                                                                onClick={open}>
                                                        <AddCircleOutlineIcon fontSize='large' color="primary"/>
                                                    </IconButton>
                                                </div>
                                                <div {...getRootProps({className: "dropzone"})}
                                                     style={{
                                                         minHeight: '80px',
                                                         padding: '7px',
                                                         border: '#1565c0 dotted'
                                                     }}>
                                                    <input {...getInputProps()} />
                                                    <div>{claimFiles.map(elt =>
                                                        <ButtonDocument key={elt.name}
                                                                        name={elt.name}/>)}
                                                    </div>
                                                </div>
                                            </div>
                                            <MyModal open={openExclusionsModal}
                                                     onClose={() => {
                                                         setOpenExclusionsModal(false)
                                                     }}>
                                                <Exclusions/>
                                            </MyModal>
                                            <Typography fontSize={18} style={{marginTop: '30px'}}>
                                                Vous confirmez que ces soins ne font pas <Link
                                                style={{cursor: 'pointer'}} onClick={() => {
                                                setOpenExclusionsModal(true)
                                            }}> l’objet d’exclusions</Link> ? Appelez-nous au 07 72 10 12 05 en cas de
                                                doute !
                                            </Typography>
                                        </section>
                                        <div style={{marginTop: 60, display: 'flex', justifyContent: 'center'}}>
                                            {isLoading ?
                                                <CircularIndeterminate/> :
                                                <Button variant="contained"
                                                        type="submit"
                                                        disabled={!isDirty || !isValid}>
                                                    Valider la demande
                                                </Button>}
                                        </div>
                                        {errorMessage?.length ?
                                            <div style={{marginTop: 20, marginLeft: 60}}>
                                                <Typography style={{color: '#d32f2f'}}>
                                                    Une erreur est survenue durant la création de la demande:
                                                    <br/>
                                                    {errorMessage}
                                                </Typography>
                                            </div> :
                                            <></>}
                                    </>}
                            </form>
                        }
                    </>
                }
            </section>
        </>
    );
}
