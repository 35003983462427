import axiosAPI from "./axiosApi";

export const getAccount = () => (axiosAPI.get('account/'));

export const subscribe = (data) => (axiosAPI.post('subscribePartner/', data));

export const login = (reqData) => (
    axiosAPI.post('loginPartner/', reqData)
    .then(({data, status}) => {
        return new Promise((resolve, reject) => {
            if (status === 200) {
                axiosAPI.defaults.headers["Authorization"] = `JWT ${data.token}`;
                localStorage.setItem('access_token', data.access);
                localStorage.setItem('refresh_token', data.refresh);
                resolve(data);
            } else {
                reject(data);
            }
        });
    }));

export const checkChipNumber = (data) => (axiosAPI.post('checkChipNumber/', data));

export const checkInsuredAnimal = (data) => (axiosAPI.post('checkInsuredAnimal/', data));

export const createClaim = (data) => (axiosAPI.post('createClaim/', data));

export const getQuoteClaim = (data) => (axiosAPI.post('getQuoteClaim/', data));
