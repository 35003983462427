import React from "react";
import Typography from "@mui/material/Typography";
import {Button, CircularProgress, TextField} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {login} from "../../utils/axiosRequests";
import {makeStyles} from "@mui/styles";
import {useDispatch} from "react-redux";
import {getAccount, selectNumberOrder} from "../accountSlice";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";


const useStyles = makeStyles(() => ({
    card: {
        marginTop: '20px',
        backgroundColor: 'aliceblue',
        borderRadius: '20px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '30px',
        paddingBottom: '30px',
    }
}));

const schema = yup.object().shape({
    email: yup.string().email().required(),
    number_order: yup.number().required(),
});

export default function Login() {
    const classes = useStyles()
    const { control, formState, handleSubmit} = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const {isValid, isDirty} = formState
    const navigate  = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const onSubmit = (data) => {
        setIsLoading(true)
        login(data)
            .then(() => {
                dispatch(selectNumberOrder(data.number_order))
                dispatch(getAccount())
                     .then(() => {
                         setIsLoading(false)
                         navigate(`/claim`);
                     });
            })
            .catch(error => {
                setErrorMessage(error.response.data)
                setIsLoading(false)
            })
    };

    const redirectToSubscription = () => {
        navigate(`/login/subscribe`);
    }
    return (
        <>
            <Typography variant={"h2"} style={{display: 'flex', justifyContent: 'center', fontSize: '40px'}}>
                Connexion
            </Typography>

            <Typography fontSize={18} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
                Première connexion ? <Button onClick={redirectToSubscription}>S'inscrire et activer le tiers payant en 30 secondes !</Button>
            </Typography>
            <section style={{marginTop: '50px', display: 'flex', justifyContent: 'center'}}>
                {isLoading ? <CircularProgress/> : <></>}
                <div className={classes.card}
                     style={{ display: isLoading ? 'none' : 'block' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <section>
                            <div style={{ marginBottom: '30px' }}>
                                <Controller name="email"
                                            style={{ width: '400px', margin: '50px'}}
                                            defaultValue={undefined}
                                            render={({field: {onChange, value}, fieldState: {error}}) => (
                                                <TextField value={value}
                                                           onChange={onChange}
                                                           type={"email"}
                                                           error={!!error}
                                                           placeholder="Email"/>
                                            )}
                                            control={control}/>
                            </div>
                            <div>
                                <Controller name="number_order"
                                            style={{ width: '400px'}}
                                            defaultValue={undefined}
                                            render={({field: {onChange, value}, fieldState: {error}}) => (
                                                <TextField value={value}
                                                           onChange={onChange}
                                                           type={"number"}
                                                           error={!!error}
                                                           placeholder="Numéro d'ordre"/>
                                            )}
                                            control={control}/>
                            </div>
                        </section>
                        <div style={{marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Button variant="contained"
                                    type="submit"
                                    style={{ width: '150px' }}
                                    disabled={!isValid || !isDirty}>Se connecter</Button>
                            {errorMessage?.length ? <Typography style={{ marginTop: '20px', color : '#d32f2f' }}>{errorMessage}</Typography> : <></>}
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
