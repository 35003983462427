import React from "react";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function Success() {
    const navigate = useNavigate();
    const redirectToClaim = () => {
        navigate('/claim');
    }

    return (
        <section style={{ marginLeft: '50px', marginTop: '150px'}}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
                <CheckCircleRoundedIcon fontSize={'large'} color="success" style={{ marginRight: '30px', zoom: 2}}/>
                <Typography fontSize={'22px'}>
                    Votre demande de remboursement a bien été envoyée.
                    <br/>
                    Nous allons effectuer le versement dans les plus brefs délais.
                </Typography>
            </div>
            <Button variant="contained"
                    onClick={redirectToClaim}
                    style={{ marginTop: '100px' }}>
                Faire une autre demande
            </Button>
        </section>
    );
}
