import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from "date-fns/locale/fr";
import {LocalizationProvider} from "@mui/lab";
import store from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import './index.css';
import CircularIndeterminate from "./components/molecules/Loading";

let persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDateFns}
                              locale={frLocale}>
            <Provider store={store}>
                <PersistGate loading={<CircularIndeterminate/>} persistor={persistor}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
