import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import * as api from "../utils/axiosRequests";

const initialState = {
    status: 'idle',
    id: null,
    firstName: '',
    lastName: '',
    socialReason: '',
    numberOrder: '',
}

export const getAccount = createAsyncThunk(
    'getAccount',
    async () => {
        const response = await api.getAccount()
        return response.data;
    });


export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        reset: (state) => {
            state.status = initialState.status;
            state.id = initialState.null;
            state.firstName = initialState.firstName
            state.lastName = initialState.lastName
            state.socialReason = initialState.socialReason
            state.numberOrder = initialState.numberOrder
        },
        selectNumberOrder: (state, action) => {
            state.numberOrder = action.payload
        }
    },
    extraReducers: {
        [getAccount.pending]: (state) => {
            state.status = 'loading';
        },
        [getAccount.fulfilled]: (state, {payload}) => {
            state.status = 'succeeded';
            state.id = payload.id
            state.firstName = payload.first_name
            state.lastName = payload.last_name
            state.socialReason = payload.social_reason
        },
        [getAccount.rejected]: (state, {payload}) => {
            state.status = 'failed';
        },
    }
})

// Action creators are generated for each case reducer function
export const { reset, selectNumberOrder } = accountSlice.actions

export default accountSlice.reducer