import { configureStore } from '@reduxjs/toolkit'
import {combineReducers} from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import accountReducer from '../components/accountSlice'


const persistConfig = {
    key: 'root',
    storage
};

const reducers = combineReducers({
    account: accountReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer
})
