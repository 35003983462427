import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
    box: {
        width: '1200px',
        maxHeight: '90%',
        backgroundColor: 'white',
        overflow: 'auto'
    },
    header: {
        height: 72,
        backgroundColor: 'white',
        textAlign: 'right',
        position: "relative",
        zIndex: '1000'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        position: 'absolute',
        '&:hover': {
            cursor: 'pointer'
        },
        right: 0,
        top: 0,
        transform: 'scale(3) translateX(-50%) translateY(50%)',
    },
}));

export default function MyModal({children, ...props}) {

  const classes = useStyles();

  return (
      <Modal open={props.open}
             onClose={props.onClose}
             {...props}
             className={classes.center}>
          <Box className={classes.box}>
              <div className={classes.header}>
                  <CloseIcon onClick={props.onClose} className={classes.icon}/>
              </div>
              {children}
          </Box>
      </Modal>
  );
}
