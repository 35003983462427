import {Outlet} from "react-router-dom";
import React from "react";
import {LoadScript} from '@react-google-maps/api';
import {isAuthenticated, logoutUser} from "../utils/axiosApi";
import {Button} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import logo_drooky from "../static/drooky_SVG.svg";
import logo_third_party_payment from "../static/third-party-payment.png";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {reset} from "./accountSlice";

const GoogleMapLibraries = ['places'];

export default function TemplateLayout(props) {
    const isAuth = isAuthenticated();
    const { firstName, lastName, socialReason, numberOrder } = useSelector((state) => state.account)
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(reset());
        logoutUser()
    }

    return (
        <section style={{paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px'}}>
            <div style={{ height: '130px' }}>
                <img src={logo_drooky} alt='juno_logo' style={{ width: '190px', position: 'absolute', left: 30, top: 10 }}/>
                <img src={logo_third_party_payment} alt='juno_logo' style={{ width: '170px', position: 'absolute', right: 120, top: 30 }}/>
                <Typography style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px'}}>Bienvenue sur votre espace vétérinaire Drooky </Typography>
                {isAuth ?
                    <Typography style={{ display: 'flex', justifyContent: 'center'}}>Connecté(e) en tant que {socialReason ? socialReason : `${lastName} ${firstName}`} - N°{numberOrder}</Typography> :
                    null}
                <Button style={{display: isAuth ? 'block' : 'none', width: '64px', position: 'absolute', top: 30, right: 30}} onClick={() => logout()}>
                    <LogoutIcon/>
                </Button>
            </div>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        libraries={GoogleMapLibraries}>
                <Outlet/>
            </LoadScript>
        </section>
    );
}
