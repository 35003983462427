import React from 'react';
import Router from './routes';
function App() {

  return (
      <Router />
  );
}

export default App;
