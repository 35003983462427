import React from "react";
import Typography from "@mui/material/Typography";

export default function Exclusions() {
    return (
        <section style={{ margin: '50px', marginTop: '10px'}}>
            <Typography>
                Nous ne souhaitons pas assurer et vous ne serez en aucun cas indemnisé ni ne recevrez de nous aucune prestation pour :
                <br/>
                <br/>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les animaux autres que chien ou chat.
                <br/>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les animaux âgés de moins de 3 mois , un chien de plus de 8 ans et un chat de plus de 10 ans à la date
                de souscription initiale.
                <br/>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les frais consécutifs à (et leurs conséquences) :
                <div style={{ marginLeft: '40px'}}>
                    <li>Tout Accident survenu avant la fin du Délai de carence.</li>
                    <li>Une Maladie ou un état pathologique quelconque dont la première manifestation a pu être constatée avant la fin du Délai de carence.</li>
                    <li>Une maladie héréditaire ou congénitale.</li>
                </div>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Tout sinistre ou fraction de sinistre dont le remboursement conduirait à dépasser la limite annuelle de remboursement.
                <br/>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les sinistres causés ou provoqués intentionnellement par le Souscripteur ou l’Assuré ou avec sa complicité.
                <br/>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les frais occasionnés ou aggravés par les événements suivants :
                <div style={{ marginLeft: '40px'}}>
                    <li>Mauvais traitements, manque de soins ou défaut de nourriture imputable à l’Assuré ou à la personne qui a la garde de l’animal.</li>
                    <li>Participation de l’Animal assuré à quelque titre que ce soit à une action de chasse, de combats organisés, de courses, de compétitions sportives ainsi qu’à leurs entraînements.</li>
                    <li>Utilisation de l’Animal assuré dans le cadre d’une activité professionnelle comme par exemple dans le cadre de gardiennage ou sauvetage.</li>
                    <li>Faits de guerre (civile ou étrangère), émeutes et mouvements populaires.</li>
                    <li>La désintégration du noyau atomique, tremblement de terre, une inondation, une éruption volcanique ou tout autre cataclysme, ou pandémie vétérinaire.</li>
                </div>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les frais relatifs aux actes suivants :
                <div style={{ marginLeft: '40px'}}>
                    <li>Non pratiqués ou prescrits par un docteur Vétérinaire régulièrement inscrit à l’Ordre des Vétérinaires de France.</li>
                    <li>Liés à des Maladies qui auraient normalement pu être évitées si les vaccins préventifs avaient été à jour :
                        <div style={{ marginLeft: '40px'}}>- Pour les chiens : Maladie de Carré, Hépatite, Leptospirose, Parvovirose.</div>
                        <div style={{ marginLeft: '40px'}}>- Pour les chats : Leucose féline, Coryza, Panleucopénie féline (Typhus).</div>
                    </li>
                    <li>Tout acte pratiqué sans rapport avec la pathologie déclarée.</li>
                    <li>Le suivi, de l’induction ou de l’interruption de chaleurs, de l’insémination artificielle et la congélation de semence</li>
                    <li>Les mises bas et les césariennes.</li>
                    <li>Les interventions chirurgicales visant à corriger les défauts anatomiques ou ayant un but esthétique et
                        toute opération de convenance et leurs conséquences (par exemple : taille des oreilles ou de la queue,
                        les entropions, les ectropions et les hernies ombilicales, la coupe des griffes, ablation des ergots,
                        syndrome brachycéphale, etc.).</li>
                    <li>Les soins d’hygiène et de toilettage.</li>
                    <li>Les dépistages en l’absence de problème de santé (par exemple : dépistages de leishmaniose, dépistages de tares oculaires, dépistages de dysplasie, dépistages génétiques).</li>
                    <li>Les contrôles de croissance.</li>
                    <li>Les frais de titrage des anticorps antirabiques, les visites pour chiens mordeurs.</li>
                    <li>L’identification : puce électronique ou tatouage.</li>
                    <li>Les frais de pension.</li>
                    <li>L’euthanasie sauf si elle est consécutive à un Accident ou une Maladie.</li>
                    <li>L’inhumation et l’incinération.</li>
                    <li>L’autopsie.</li>
                    <li>L’établissement d’un passeport ou de tout autre document.</li>
                    <li>Les conséquences de l’absence de détartrage, l’exérèse des dents de lait ou l’exérèse des dents dans un but esthétique ou de convenance.</li>
                </div>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les frais exposés suite à :
                <div style={{ marginLeft: '40px'}}>
                    <li>Une anomalie constitutionnelle, aux infirmités, aux malformations, aux pathologies d’origine congénitale
                        et/ou héréditaire et leurs conséquences ainsi que les frais de dépistage, y compris la dysplasie de la
                        hanche et du coude, (non union du processus anconé, ostéochondrite disséquante du coude, fragmentation
                        du processus coronoïde l’ulna, incongruence articulaire), les atteintes des cartilages de l’épaule
                        (ostéochondrite et ostéochondrose disséquantes), les (sub)luxations des rotules des chiens de petite
                        taille, les anomalies des narines, du palais et du larynx des races brachycéphales, l’ectopie
                        testiculaire y compris les frais de dépistage de ces pathologies.</li>
                    <li>La rage, toutes maladies contagieuses (épizootie) entraînant l’abattage de l’animal.</li>
                    <li>La gestation : diagnostic, suivi de gestation, interruption volontaire de gestation et ses conséquences une pathologie comportementale (visites et traitements).</li>
                </div>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les frais engagés pour les produits suivants :
                <div style={{ marginLeft: '40px'}}>
                    <li>Médicaments prescrit sans rapport avec la pathologie déclarée.</li>
                    <li>Produit qui n’a pas fait l’objet d’une prescription par un Vétérinaire (ordonnance).</li>
                    <li>Tout produit qui n’est pas un médicament ayant eu une autorisation de mise sur le marché en France.</li>
                    <li>Produits et médicaments liés aux troubles du comportement.</li>
                    <li>Aliments (médicamenteux, diététiques ou thérapeutiques)</li>
                    <li>Appareillages et objets médicaux.</li>
                    <li>Prothèses dentaires, oculaires et prothèses de la hanche</li>
                    <li>Les produits d’alimentation nutritionnel, thérapeutique ou les compléments alimentaires.</li>
                    <li>Les produits d’entretien et d’hygiène (lotions, shampooings, dentifrices).</li>
                    <li>Les médicaments pour interrompre les chaleurs ou la gestation, ou provoquer une contraception chimique.</li>
                </div>
                <span style={{ marginRight: '20px'}}>&#8594;</span>Les frais suivants de bien-être ou de prévention :
                <div style={{ marginLeft: '40px'}}>
                    <li>Vaccins et consultations vaccinales.</li>
                    <li>Bilan de santé.</li>
                    <li>Evaluations comportementales.</li>
                    <li>Phytothérapie.</li>
                    <li>Produits antiparasitaires (antipuces, anti-poux, anti-tiques, vermifuges, etc.).</li>
                    <li>Médicaments homéopathiques.</li>
                    <li>Les produits utilisés en phytothérapie.</li>
                    <li>Frais de stérilisation ou castration (ainsi que les frais médicaux liés à ces actes).</li>
                    <li>Détartrages et soins buccodentaires.</li>
                </div>
            </Typography>
        </section>
    );
}
