import axios from 'axios'

const baseURL = process.env.REACT_APP_BACKEND_URL

const accessToken = localStorage.getItem("access_token") || null;

const axiosInstance = axios.create({
    baseURL,
    timeout: 0,
    headers: {
        'Authorization': accessToken ? `JWT ${accessToken}` : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use(
    response => {
        if (response.status === 200)
            return Promise.resolve(response);
        else
            return Promise.reject(response);
    },
    error => {
        const { response, config: originalRequest } = error;
        // Prevent infinite loops
        if (response.status === 401 && originalRequest.url === `${baseURL}token/refresh/`) {
            window.location.href = '/login';
            return Promise.reject(error);
        }
        if (
            response.data.code === "token_not_valid" &&
            response.status === 401
            // && response.statusText === "Unauthorized"
        ) {
            const refreshToken = localStorage.getItem('refresh_token');
            if (!refreshToken) {
                window.location.href = '/login';
                return;
            }
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
            // exp date in token is expressed in seconds, while now() returns milliseconds:
            const now = Math.ceil(Date.now() / 1000);

            if (tokenParts.exp > now) {
                return axiosInstance
                    .post('/token/refresh/', { refresh: refreshToken })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);
                        axiosInstance.defaults.headers['Authorization'] = `JWT ${response.data.access}`;
                        originalRequest.headers['Authorization'] = `JWT ${response.data.access}`;
                        return axiosInstance(originalRequest);
                    }).catch(err => {
                        console.log('ERROR: ', err);
                    });
            } else{
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                axiosInstance.defaults.headers['Authorization'] = null;
                originalRequest.headers['Authorization'] = null;
                return axiosInstance(originalRequest);
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export const isAuthenticated = () => {
    const token = localStorage.getItem("access_token");
    return !!token;
};

export const logoutUser = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = '/';
}

export default axiosInstance
