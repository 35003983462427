import * as React from 'react';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import * as yup from "yup";
import "yup-phone";
import {IconButton, TextField} from "@mui/material";
import CircularIndeterminate from "../molecules/Loading";
import {useNavigate} from "react-router-dom";
import {Autocomplete} from '@react-google-maps/api';
import _ from "lodash";
import {subscribe} from "../../utils/axiosRequests";
import HelloSign from "hellosign-embedded";
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const get_partner_inputs_1 = () => [
    {
        label: 'Raison sociale',
        name: 'social_reason',
        type: 'text'
    },
];

const get_partner_inputs_2 = () => [
    {
        label: 'IBAN',
        name: 'iban',
        type: 'text'
    },
    {
        label: 'Numéro(s) d\'ordre',
        name: 'numbers_order',
        type: 'number'
    },
]

const get_user_left_inputs = () => [
    {
        label: 'Prénom',
        name: 'firstname',
        type: 'text'
    },
    {
        label: 'Nom',
        name: 'lastname',
        type: 'text'
    },
    {
        label: 'Adresse',
        name: 'address',
        type: 'text'
    },
];

const get_user_right_inputs = () => [
    {
        label: 'Email',
        name: 'email',
        type: 'email'
    },
    {
        label: 'Téléphone',
        name: 'phone',
        type: 'tel'
    },
]

const useStyles = makeStyles(() => ({
    input: {
        '& input': {
            padding: 10
        },
        '& fieldset': {
            borderWidth: 2
        },
        width: 300,
        "@media (max-width: 1160px)": {
            width: 250,
        }
    },
    card: {
        marginTop: '20px',
        backgroundColor: 'aliceblue',
        borderRadius: '20px',
        padding: '20px',
        width: '1100px',
        "@media (max-width: 1160px)": {
            width: '100%',
        }
    },
}));

// const ibanRegex = /^$|^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;
const ibanRegex = /^$|^(?:NO(\d|[A-Z]){13}|(?:DK|FI|GL|FO|NL)(\d|[A-Z]){16}|MK(\d|[A-Z]){17}|(?:AT|EE|KZ|LU|XK)(\d|[A-Z]){18}|(?:BA|HR|LI|CH|CR|LV)(\d|[A-Z]){19}|(?:GE|DE|LT|ME|RS|BG|BH|GB|IE)(\d|[A-Z]){20}|(?:IL|GI)(\d|[A-Z]){21}|(?:AD|CZ|ES|MD|SA|RO)(\d|[A-Z]){22}|PT(\d|[A-Z]){23}|(?:BE|IS|IT|SM)(\d|[A-Z]){24}|(?:FR|MR|MC|CY)(\d|[A-Z]){25}|(?:AL|DO|LB|PL)(\d|[A-Z]){26}|(?:AZ|HU)(\d|[A-Z]){27}|(?:GR|MU|KW)(\d|[A-Z]){28}|MT(\d|[A-Z]){29})$/;
const phoneFrRegex = /^$|^(\+33|33|0)(1|6|7|9)\d{8}$/;

const schema = yup.object().shape({
    social_reason: yup.string().nullable().optional(),
    email: yup.string().email().required(),
    phone: yup.string().notRequired().matches(phoneFrRegex).required(),
    iban: yup.string().transform(x => x?.replaceAll(/ /g, "")).matches(ibanRegex).required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    address: yup.string().required(),
});

export default function Subscribe(props) {

    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [userAddressComponents, setUserAddressComponents] = React.useState(null);
    const [numbersOrderValue, setNumbersOrderValue] = React.useState([]);

    const user_left_inputs = get_user_left_inputs();
    const user_right_inputs = get_user_right_inputs();
    const partner_inputs_1 = get_partner_inputs_1();
    const partner_inputs_2 = get_partner_inputs_2();

    const {control, formState, handleSubmit, reset, setValue} = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const {isValid, isDirty} = formState
    const navigate = useNavigate();
    const [autoComplete, setAutoComplete] = React.useState(null);
    const onLoad = (autoComplete) => {
        setAutoComplete(autoComplete)
    }
    const onPlacesChanged = () => {
        const place = autoComplete.getPlace();
        const hasPostalCode = !!place.address_components.find(a => a.types.includes('postal_code'))
        if (!hasPostalCode) return
        setValue('address', place.formatted_address)
        setUserAddressComponents(place.address_components)
    };

    const hs = React.useRef();

    const onSubmit = (data) => {
        data = _.mapValues(data, v => v?.trim())
        const dataToSend = {
            ..._.omit(data, ['address']),
            address: userAddressComponents,
            numbers_order: numbersOrderValue
        }
        setIsLoading(true)
        setErrorMessage(null)

        try {
            subscribe(dataToSend).then(
                response => {
                    const clientId = process.env.REACT_APP_HELLO_SIGN_CLIENT_ID
                    hs.current = new HelloSign({
                        clientId,
                        ...process.env.REACT_APP_HELLO_SIGN_PROD === "true" ? {} : { skipDomainVerification: true }
                    });
                    //on modal close, reset states to avoid loop when reopening it
                    hs.current.on('cancel', () => {
                        setIsLoading(false)
                    });
                    hs.current.on('error', (dta) => {
                        setIsLoading(false)
                        setErrorMessage('error hellosign')
                    });
                    hs.current.on('sign', () => {
                        setTimeout(() => {
                            setIsLoading(false)
                            redirectToLogin();
                        }, 2000)
                    });
                    hs.current.open(response.data);
                }
            ).catch(error => {
                setErrorMessage(error.response?.data)
                setIsLoading(false)
            });
        } catch (error) {
            setErrorMessage(error)
            setIsLoading(false)
        }
    };

    const handleDelete = i => {
        setNumbersOrderValue(numbersOrderValue.filter((tag, index) => index !== i));
    };

    const handleAddition = value => {
        if (value > 0 && !numbersOrderValue.includes(value)) {
            setNumbersOrderValue([...numbersOrderValue, value]);
            setValue('numbers_order', 'e')
        }
    };

    const onReset = () => {
        reset();
        setNumbersOrderValue([]);
        setUserAddressComponents(null)
    }

    const redirectToLogin = () => {
        navigate('/login');
    }

    return (
        <>
            <Typography variant={"h2"} style={{display: 'flex', justifyContent: 'center', fontSize: '40px'}}>
                Inscription
            </Typography>

            <Typography fontSize={18} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
                Vous avez déjà un compte ? <Button onClick={redirectToLogin}>Se connecter</Button>
            </Typography>


            <section style={{marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center'}}>
                <form onSubmit={handleSubmit(onSubmit)}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                      onReset={onReset}>
                    <section className={classes.card}>
                        <Typography variant={"h5"} marginBottom={'30px'}>
                            Contact ou représentant
                        </Typography>
                        <Stack direction="row"
                               justifyContent="center"
                               alignItems="flex-start"
                               spacing={2}>
                            <Box style={{width: '100%'}}>
                                {user_left_inputs.map(input =>
                                    <ListItem style={{height: '50px', maxWidth: "500px", minWidth: "450px"}}
                                              key={input.name}
                                              disablePadding>
                                        <ListItemIcon>
                                            <InfoOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={input.label + ':'}/>
                                        <Controller control={control}
                                                    name={input.name}
                                                    defaultValue={undefined}
                                                    render={({field, fieldState}) =>
                                                        input.name === 'address' ?
                                                        <Autocomplete onLoad={onLoad}
                                                                      onPlaceChanged={onPlacesChanged}
                                                                      options={{
                                                                          types: ['street_address', 'route'],
                                                                          componentRestrictions: {country: ["fr", "gp", "mf", "re", "mq"]}
                                                                      }}>
                                                            <TextField InputProps={{ className: classes.input }}
                                                                       placeholder={"Recherchez..."}
                                                                       error={fieldState.isDirty && !userAddressComponents}
                                                                       {...field}/>
                                                        </Autocomplete> :
                                                        <TextField type={input.type}
                                                                   onKeyPress={(e) => {
                                                                       if (input.name === 'phone' && isNaN(e.key) && e.key !== '+') e.preventDefault();
                                                                   }}
                                                                   InputProps={{className: classes.input}}
                                                                   error={!!fieldState.error}
                                                                   {...field}/>
                                                    }/>
                                    </ListItem>
                                )}
                            </Box>
                            <Box style={{width: '100%'}}>
                                {user_right_inputs.map(input =>
                                    <ListItem style={{height: '50px', maxWidth: "500px", minWidth: "450px"}}
                                              key={input.name}
                                              disablePadding>
                                        <ListItemIcon>
                                            <InfoOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={input.label + ':'}/>
                                        <Controller control={control}
                                                    name={input.name}
                                                    defaultValue={undefined}
                                                    render={({field, fieldState}) =>
                                                        <TextField type={input.type}
                                                                   InputProps={{className: classes.input}}
                                                                   error={!!fieldState.error}
                                                                   {...field}/>
                                                    }/>
                                    </ListItem>
                                )}
                            </Box>
                        </Stack>
                    </section>
                    <section className={classes.card}>
                        <Typography variant={"h5"} marginBottom={'30px'}>
                            Si vous êtes une clinique
                        </Typography>
                        <Stack direction="row"
                               justifyContent="center"
                               alignItems="flex-start"
                               spacing={2}>
                            <Box style={{width: '100%'}}>
                                {partner_inputs_1.map(input =>
                                    <ListItem style={{height: '50px', maxWidth: "600px", minWidth: "520px"}}
                                              key={input.name}
                                              disablePadding>
                                        <ListItemIcon>
                                            <InfoOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={input.label + ':'}/>
                                        <Controller control={control}
                                                    name={input.name}
                                                    defaultValue={undefined}
                                                    render={({field, fieldState}) =>
                                                        <TextField type={input.type}
                                                                   onKeyPress={(e) => {
                                                                       if (input.name === 'phone' && isNaN(e.key) && e.key !== '+') e.preventDefault();
                                                                   }}
                                                                   InputProps={{className: classes.input}}
                                                                   error={!!fieldState.error}
                                                                   {...field}/>
                                                    }/>
                                    </ListItem>
                                )}
                            </Box>
                        </Stack>
                    </section>
                    <section className={classes.card}>
                        <Typography variant={"h5"} marginBottom={'30px'}>
                            Informations de paiement
                        </Typography>
                        <Stack direction="row"
                               justifyContent="center"
                               alignItems="flex-start"
                               spacing={2}>
                            <Box style={{width: '100%'}}>
                                <ListItem style={{height: '50px', maxWidth: "600px", minWidth: "520px"}}
                                          key={partner_inputs_2[0].name}
                                          disablePadding>
                                    <ListItemIcon>
                                        <InfoOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={partner_inputs_2[0].label + ':'}/>
                                    <Controller control={control}
                                                name={partner_inputs_2[0].name}
                                                defaultValue={undefined}
                                                render={({field, fieldState}) =>
                                                    <TextField type={partner_inputs_2[0].type}
                                                               InputProps={{className: classes.input}}
                                                               error={!!fieldState.error}
                                                               {...field}/>
                                                }/>
                                </ListItem>
                                <ListItem style={{height: '50px', maxWidth: "600px", minWidth: "520px"}}
                                          key={partner_inputs_2[1].name}
                                          disablePadding>
                                    <ListItemIcon>
                                        <InfoOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={partner_inputs_2[1].label + ':'}/>
                                    <Controller control={control}
                                                name={partner_inputs_2[1].name}
                                                defaultValue={undefined}
                                                render={({field, fieldState}) =>
                                                    <>
                                                        <TextField type={partner_inputs_2[1].type}
                                                                   min={0}
                                                                   placeholder={'Validez avec "Entrée"'}
                                                                   onKeyPress={(e) => {
                                                                       if (e.key === 'Enter' || e.key === ',') {
                                                                           e.preventDefault();
                                                                           handleAddition(e.target.value)
                                                                       }
                                                                   }}
                                                                   InputProps={{className: classes.input, inputProps: { min: 1 } }}
                                                                   error={fieldState.isDirty && !_.isNaN(field.value) && field.value < 1}
                                                                   {...field}/>
                                                        <IconButton style={{ marginLeft: '10px', position: 'absolute', right: -60}}
                                                                    disabled={isNaN(field?.value) || field.value < 1}
                                                                    onClick={() => {
                                                                        handleAddition(field.value)
                                                                    }}>
                                                            <AddCircleOutlineIcon fontSize='large'
                                                                                  color={(isNaN(field?.value) || field.value < 1) ? 'gray' : 'primary'} />
                                                        </IconButton>
                                                    </>
                                                }/>
                                </ListItem>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                                    {numbersOrderValue.map((numberOrder, index) =>
                                        <div key={'numberOrder_' + index}
                                             style={{display: 'flex', alignItems: 'center', marginLeft: '15px', marginRight: '15px', borderRadius: '10%', backgroundColor: 'lightgray', paddingRight: '10px', paddingLeft: '10px'}}>
                                            <Typography style={{fontSize: '20px'}}>{numberOrder}</Typography>
                                            <IconButton onClick={(event) => handleDelete(index)}
                                                        style={{ padding: 0, marginLeft: '10px'}}>
                                                <ClearIcon color="primary"/>
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </Box>
                        </Stack>
                    </section>
                    <div style={{marginTop: 60, marginBottom: 60, display: 'flex', justifyContent: 'center'}}>
                        {isLoading ?
                            <CircularIndeterminate/> :
                            <>
                                <Button variant="contained"
                                        type="submit"
                                        disabled={!isValid || !isDirty || !userAddressComponents || !numbersOrderValue?.length}>Signer le contrat</Button>
                                <Button variant="contained"
                                        style={{marginLeft: 60}}
                                        type="reset"
                                        disabled={!isDirty}>Annuler</Button>
                            </>}
                    </div>
                    {errorMessage?.length ?
                        <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                            <Typography style={{color: '#d32f2f'}}>
                                Une erreur est survenue durant l'inscription:
                                <br/>
                                {errorMessage}
                            </Typography>
                        </div> :
                        <></>}
                </form>
            </section>
        </>
    );
}
